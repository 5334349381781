@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&family=Nunito:wght@200&display=swap");
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

body {
  height: 100vh;
}

.navbar {
  background: rgba(123, 194, 209, 0.6);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: block;
  padding: 0.7rem 1rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.navbar a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
}

.navbar a:hover {
  background: rgba(0, 0, 0, 0.3);
}

.navbar a.active {
  background: rgb(255, 222, 203);
  color: rgb(10, 28, 33);
}

.signInPage, .registerPage {
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signInPage .signIn-header, .signInPage .register-header, .registerPage .signIn-header, .registerPage .register-header {
  font-family: "Fuzzy Bubbles", cursive;
  font-size: 58px;
  text-align: center;
  width: 100%;
  color: #181818;
  margin: 0;
}
.signInPage .signIn-greeting, .signInPage .register-greeting, .registerPage .signIn-greeting, .registerPage .register-greeting {
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-size: 24px;
  width: 240px;
  margin: 0 auto;
  color: #181818;
}
.signInPage p, .registerPage p {
  margin-top: 15px;
}
.signInPage .signIn-from, .signInPage .register-form, .registerPage .signIn-from, .registerPage .register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signInPage .signIn-from .signIn, .signInPage .signIn-from .register, .signInPage .register-form .signIn, .signInPage .register-form .register, .registerPage .signIn-from .signIn, .registerPage .signIn-from .register, .registerPage .register-form .signIn, .registerPage .register-form .register {
  width: 250px;
  margin: 5px auto;
  padding: 15px 30px;
  color: gray;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
  border: none;
  outline: none;
}
.signInPage .signIn-from #forgortPassword, .signInPage .register-form #forgortPassword, .registerPage .signIn-from #forgortPassword, .registerPage .register-form #forgortPassword {
  text-align: end;
  width: 310px;
  margin: 0 auto;
  text-decoration: none;
  font-family: "Nunito", sans-serif;
  color: #555555;
  cursor: pointer;
}
.signInPage .signIn-from #signIn, .signInPage .signIn-from #create, .signInPage .register-form #signIn, .signInPage .register-form #create, .registerPage .signIn-from #signIn, .registerPage .signIn-from #create, .registerPage .register-form #signIn, .registerPage .register-form #create {
  width: 200px;
  margin: 10px auto;
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #181818;
  background-color: rgba(123, 194, 209, 0.6);
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
}
.signInPage .signIn-RegisterBtn, .registerPage .signIn-RegisterBtn {
  position: relative;
  margin: 20px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
.signInPage .signIn-RegisterBtn .signIn-RegisterBtn-container, .registerPage .signIn-RegisterBtn .signIn-RegisterBtn-container {
  background-color: #FFA876;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
}
.signInPage .signIn-RegisterBtn .signIn-RegisterBtn-container #signInOrRegisterBtn, .registerPage .signIn-RegisterBtn .signIn-RegisterBtn-container #signInOrRegisterBtn {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  color: black;
}
.signInPage .signIn-RegisterBtn .signIn-RegisterBtn-container .active, .registerPage .signIn-RegisterBtn .signIn-RegisterBtn-container .active {
  border-radius: 25px;
  background-color: #fcfcfc;
}
.signInPage .signIn-RegisterBtn .signIn-RegisterBtn-container .inactive, .registerPage .signIn-RegisterBtn .signIn-RegisterBtn-container .inactive {
  background-color: transparent;
}

/*stylet af Michelle */
.Btn {
  height: 25px;
  width: 50px;
  background: #FFA876;
  border: none;
  border-radius: 25px;
  position: relative;
  right: 15px;
}

.New {
  height: 40px;
  width: 275px;
  background: #fcfcfc;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: black;
}
.New h2 {
  position: inherit;
}

.plus {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: relative;
  right: 5px;
  font-size: 35px;
}

.frontpage {
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
  height: 100vh;
}

.Top {
  height: 50px;
  position: absolute;
  right: 20px;
  top: 20px;
}
.Top .ikon {
  font-size: 30px;
  height: 40px;
  width: 40px;
  color: #181818;
}

.frontpageHeader {
  font-family: "Fuzzy Bubbles", cursive;
  font-size: 58px;
  text-align: center;
  width: 100%;
  color: #181818;
  padding: 30px 0 0;
}

.box {
  height: 260px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito", sans-serif;
}
.box .Container {
  height: 235px;
  width: 310px;
  border-radius: 30px;
  background: #fcfcfc;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  margin: 10px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}
.box .Container h4 {
  margin-left: 10px;
}
.box .opgave_container {
  height: 45px;
  width: 90%;
  background: #fcfcfc;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}
.box .opgave_container p {
  padding-left: 10px;
  font-family: "Nunito", sans-serif;
}
.box .Opgave1 {
  margin: 10px auto;
  width: 100%;
}
.box .Opgave_container2 {
  height: 45px;
  width: 100%;
  background: #fcfcfc;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box .Opgave2 {
  position: relative;
  bottom: 30px;
  left: 10px;
}

.List_container {
  height: 125px;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  font-family: "Nunito", sans-serif;
}

.addnew {
  height: 60px;
  position: relative;
  top: 20px;
  display: flex;
  justify-content: center;
  font-family: "Nunito", sans-serif;
}

.Insert_text {
  position: relative;
  left: 10px;
  top: 15px;
}

.List_one {
  height: 125px;
  width: 125px;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.List_one h4 {
  width: 90%;
  margin: 0 auto;
}

.List_two {
  height: 125px;
  width: 125px;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.List_two h4 {
  width: 90%;
  margin: 0 auto;
}

.List_arrow {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 15px;
}

.addTask {
  height: 100vh;
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.T {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tilbage_pil {
  height: 35px;
  width: 35px;
  background: #fcfcfc;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.ny_opgave {
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  font-family: "Nunito", sans-serif;
}

.Task_name {
  height: 60px;
  width: 271px;
  background: #fcfcfc;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}

.When {
  height: 60px;
  width: 271px;
  background: #fcfcfc;
  border-radius: 15px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
  position: relative;
  top: 10px;
}

.hvem {
  height: 45px;
  width: 150px;
  margin-top: 50px;
  margin-left: 20px;
}

.Pr_container {
  height: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: "Nunito", sans-serif;
}

.personer {
  height: 50px;
  width: 271px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.Todo_btn {
  height: 35px;
  width: 75px;
  border-radius: 15px;
  border-style: none;
  background-color: rgba(123, 194, 209, 0.6);
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}

.overskrift h2 {
  position: relative;
  top: 10px;
  left: 10px;
}

.ikon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #fcfcfc;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  position: relative;
  right: 10px;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgrey;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background: #fcfcfc;
  transition: 0.4s;
}

input:checked + .slider {
  background: rgba(123, 194, 209, 0.6);
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.rounded {
  border-radius: 34px;
}

.slider.rounded:before {
  border-radius: 50%;
}

.profile-page {
  height: 100vh;
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
}

.back-profile {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  max-width: 100%;
  padding: 18px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  width: 55px;
  height: 55px;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  font-family: "Nunito", sans-serif;
}
.profile h1 {
  padding-bottom: 20px;
  font-family: "Fuzzy Bubbles", cursive;
}
.profile .profilicon {
  padding: 10px;
  width: 70px;
  height: 70px;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 70px;
  margin-bottom: 20px;
}
.profile p {
  padding: 5px;
}
.profile .profile-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: center;
  font-family: "Nunito", sans-serif;
}
.profile .profile-form #change {
  width: 200px;
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #181818;
  background-color: rgba(123, 194, 209, 0.6);
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
  margin-top: 50px;
}
.profile .profiletext {
  width: 250px;
  margin: 5px auto;
  padding: 15px 30px;
  color: gray;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

.personal-page {
  overflow-y: scroll;
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
  height: 100vh;
}
.personal-page .addnew {
  margin-bottom: 100px;
}

.household {
  overflow-y: scroll;
  height: 100vh;
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
  padding-bottom: 100px;
}

.personlighousehold-page {
  margin: 0;
}
.personlighousehold-page .topnav {
  display: flex;
  justify-content: space-between;
  margin: 25px;
  max-width: 100%;
}
.personlighousehold-page .topnav .backbtn {
  padding: 18px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  width: 55px;
  height: 55px;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}
.personlighousehold-page .topnav a:-webkit-any-link {
  color: #000;
  cursor: pointer;
}
.personlighousehold-page .personalBtn {
  position: relative;
  display: flex;
  justify-content: right;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
.personlighousehold-page .personalBtn-container {
  background-color: #FFA876;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
}
.personlighousehold-page .personalBtn-container #PersonalOrHouseBtn {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  color: black;
}
.personlighousehold-page .active {
  border-radius: 25px;
  background-color: #fcfcfc;
}
.personlighousehold-page .inactive {
  background: transparent;
}
.personlighousehold-page h1 {
  font-family: "Fuzzy Bubbles", cursive;
}
.personlighousehold-page .box1 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  margin: 20px 20px 0 20px;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Nunito", sans-serif;
}
.personlighousehold-page .Container1 {
  margin: 10px 20px 10px 30px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding-bottom: 22px;
  border: 1px solid #F9F9F9;
  max-width: 100%;
}
.personlighousehold-page .opgave_container11, .personlighousehold-page .Opgave_container22 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.personlighousehold-page .Opgave11, .personlighousehold-page .Opgave22 {
  padding-left: 20px;
  padding-top: 20px;
  text-align: left;
  width: 100%;
}
.personlighousehold-page .expand {
  font-size: 30px;
  background: #F9F9F9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  text-align: right;
  padding: 5px 20px 5px 0;
}
.personlighousehold-page h1 {
  margin: 32px 0;
  font-size: 33px;
  text-align: center;
}
.personlighousehold-page h2 {
  text-align: left;
  padding: 10px;
  font-family: "Nunito", sans-serif;
}
.personlighousehold-page .complete {
  text-decoration: line-through;
}

/*  Nadia*/
/* ------- MEDLEMMER SIDE ------- */
/* intro/header */
.medlemmertest {
  height: 100vh;
  width: 100%;
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
}
.medlemmertest .medlemmertest-header {
  font-family: "Fuzzy Bubbles", cursive;
  font-size: 38px;
  text-align: center;
  width: 100%;
  color: #181818;
  padding-top: 20px;
}

/* intro - tekst */
.medlemmertest-text {
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-size: 24px;
  width: 240px;
  margin: 0 auto;
  color: #181818;
}

/* medlemmer liste content(bokse)  */
.input-medlem {
  width: 45vh;
  height: 8vh;
  border-radius: 10vh;
  padding-left: 7vh;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
}

.medlem2 {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* profil ikon  */
.icon3 {
  position: relative;
  left: 6vh;
}

/* slet ikon  */
.icon4 {
  position: relative;
  right: 6vh;
  stroke-width: 20px;
}

.icon4:hover {
  color: rgba(123, 194, 209, 0.6);
  cursor: pointer;
}

/* scroll ned ikon */
.scroll-icon {
  color: rgba(123, 194, 209, 0.6);
  text-align: center;
}

.scroll-icon :hover {
  color: #60949e;
}

.scroll-container {
  text-align: center;
}

/* tilføj medlem knap */
.add-container2 {
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 250px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: bold;
  color: #181818;
  background-color: rgba(123, 194, 209, 0.6);
  cursor: pointer;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  font-family: "Nunito", sans-serif;
  /* tilføj plus ikon */
}
.add-container2 .add1-knap {
  margin: 0;
}
.add-container2 .icon5 {
  cursor: pointer;
}

/* ------- SETTINGS SIDE ------- */
.settingsmain {
  padding-top: 5px;
  margin-bottom: 100px !important;
  background: rgb(255, 222, 203);
  background-size: cover;
  background: linear-gradient(180deg, rgba(255, 222, 203, 0.6954131995) 0%, rgba(255, 219, 199, 0.7962535356) 45%, rgba(114, 230, 255, 0.1632003143) 90%);
  padding-bottom: 100px;
  /* Clear floats after the columns - header content */
  /*settings header content */
  /* vælg tema tekst */
  /*change background knap*/
}
.settingsmain .settings {
  width: 100%;
  /*intro*/
}
.settingsmain .settings .settings-header {
  font-family: "Fuzzy Bubbles", cursive;
  font-size: 38px;
  text-align: center;
  width: 100%;
  color: #181818;
  margin-top: 20px;
}
.settingsmain .row-settings:after {
  content: "";
  display: table;
  clear: both;
}
.settingsmain .column-settings {
  float: left;
  width: 50%;
  text-align: center;
  font-family: "Nunito", sans-serif;
  /*profil icon */
  /* log ud knap */
}
.settingsmain .column-settings .profilsettingsicon {
  padding: 10px;
  background: #fcfcfc;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  border-radius: 70px;
  margin-bottom: 2px;
}
.settingsmain .column-settings .button-log {
  background-color: rgba(123, 194, 209, 0.6);
  color: #181818;
  padding: 6px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  margin-top: 10px;
}
.settingsmain .settingboks-container {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* settings info(bokse)  */
}
.settingsmain .settingboks-container .settingboks {
  width: 45vh;
  height: 8vh;
  border-radius: 10vh;
  padding-left: 7vh;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background-color: #fcfcfc;
  color: #181818;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
}
.settingsmain .settingboks-container .settingboks .settingboks:hover {
  background-color: rgba(123, 194, 209, 0.6);
}
.settingsmain .settingboks-container .settingboks .settings-btn-text {
  padding-top: 2vh;
}
.settingsmain .theme-text {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 8px;
  font-family: "Nunito", sans-serif;
}
.settingsmain .buttonbackgroundcontainer {
  text-align: center;
  justify-content: center;
}
.settingsmain .buttonbackgroundcontainer .buttonbackground {
  background-color: rgba(123, 194, 209, 0.6);
  color: #181818;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  border-radius: 25px;
  width: 45vh;
  height: 8vh;
  border-radius: 10vh;
  padding-left: 7vh;
  font-size: 14px;
}
.settingsmain .buttonbackgroundcontainer .buttonbackground .btn-knaptekst {
  text-align: left;
  padding-top: 2vh;
  padding-left: 5vh;
  font-family: "Nunito", sans-serif;
}

/* ----- LIGHT/DARK MODE icon / knap fra component ld----- */
.ld-btn2 {
  position: relative;
  margin: 20px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.ld-btn2 .ld-container2 {
  background-color: #181818;
  width: 130px;
  height: 40px;
  border-radius: 15px;
  display: flex;
  box-shadow: rgb(153, 153, 153) 1.95px 1.95px 2.6px;
  /*den lyse side*/
  /*den mørke side*/
}
.ld-btn2 .ld-container2 .light-mode {
  border-radius: 15px;
  background-color: grey;
  width: 100px;
  height: 100%;
  display: flex;
  margin: 0;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
.ld-btn2 .ld-container2 .dark-mode {
  border-radius: 15px;
  background-color: #181818;
  width: 100px;
  height: 100%;
  display: flex;
  margin: 0;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  /*måne ikonet*/
}
.ld-btn2 .ld-container2 .dark-mode .icon-moon {
  color: rgba(123, 194, 209, 0.6);
}/*# sourceMappingURL=styles.css.map */